import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import BottomMenu from "../../Components/Footer-Bottom-menu";
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import {toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";

const Mysips = () => {
    const didMountRef = useRef(true)
   
    const [showSkeleton, setShowSkeleton] = useState()
    const [sipData, setSipData] = useState([])
    const [showmodal, setShowmodal] = useState(false);
    const [counter, setCounter] = React.useState(0);
    const [seconds, setseconds] = useState(0);
    const [minutes, setminutes] = useState(10);
    const [portfolioMobileData, setPortfolioMobileData] = useState("")
    const [portfolioEmailData, setPortfolioEmailData] = useState("")
    const [transId , setTransId] = useState("")
    const [tempId , setTempId] = useState("")
    const [showOtpScreen , setShowOtpScrren] = useState(false)
    const [folioNumber , setFolioNumber] = useState("")
    const [apiMessage , setAPiMessage] = useState("")
    const [warningShow, setWarningShow] = useState(false)
    const [isLoading , setIsLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [selectedActive , setSelectedActive] = useState("")
    const [otpData, setOtpData] = useState(
        {
            mobile_otp: "",
            reason:""
        },
    );
    let apiServices = new ApiService();
    const navigate = useNavigate()
    const handlemodalclose = () =>{ setShowmodal(false)
        setShowOtpScrren(false)
        setOtpData({mobile_otp:"",reason:""})
    }

    useEffect(() => {
        if (didMountRef.current) {
           
            getsipData('MONTHLY')
          
          
        }
        didMountRef.current = false;
    }, [])

    const handleInput = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setOtpData({ ...otpData, [key]: value });
    };
    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const cancelSIP = (folio , transId) => {
        if(otpData.reason == ""){
            toast.error("Please select reason for your cancellation SIP");
            return;
        }
        setIsLoading(true)
        const dataString = {
            folionumber: folioNumber
        }
        apiServices.sendotpcancelsiptransactionpostrequest(dataString).then(res => {
            if (res.data.status == "success") {
                setIsLoading(false)
                setShowOtpScrren(true)
                const maskedEmail = res.data.email
                .split('')
                .map((char, index, arr) => (index < arr.length - 14 ? 'x' : char))
                .join('');
              setPortfolioEmailData(maskedEmail);
              
              // For masking the mobile number
              const maskedMobile = res.data.mobilenumber
                .split('')
                .map((char, index, arr) => (index < arr.length - 4 ? 'x' : char))
                .join('');
              setPortfolioMobileData(maskedMobile);
                setTempId(res.data.temp_id)
                setCounter(30);
                setminutes(10);
                setseconds(0);
            }
            else{
                setIsLoading(false)
                toast(res.data.message);
            }

        })
    }
    const verifybseonboardotp = () => {
        if (otpData?.mobile_otp == "" || otpData?.mobile_otp === null) {
            toast.error("Please Enter the OTP ");
            return;
        }
        setIsLoading(true)
        const dataString1={
            otp : otpData.mobile_otp,
            temp_otp_id : tempId
          }
          apiServices.verifyotpredeemtransactionpostrequest(dataString1).then(res=>{
                if(res.data.status == "success"){
                    const dataString = {
                        transId : transId,
                        folionumber : folioNumber,
                        reason : otpData.reason,
                        temp_otp_id : tempId
                    }
                   
                    apiServices.bsecancelsippostrequest(dataString).then(res=>{
                        if(res.data.status == "success"){
                            setIsLoading(false)
                            setAPiMessage(res.data.data)
                            setShowmodal(false)
                            setShow(true)
                        }
                        else{
                            setIsLoading(false)
                            setAPiMessage(res.data.data)
                            setShowmodal(false)
                            setWarningShow(true)
                        }
                    })
                }
                else{
                    setIsLoading(false)
                    toast(res.data.message);
                }
          })



    }
    const resendOtpbr = () => {
        const dataString = {
            mobile_otp: otpData.mobile_otp
        }
        apiServices.bseendonboardingotpUrlPostRequest(dataString).then(res => {
            if (res.data.status == 'success') {
                setCounter(30);
                // setCountermob(30)
                setminutes(10);
                setseconds(0);
                return false;
            }
            else {
                toast(res.data.message);
                return false;
            }
        })
    }
    const showreasonmodal=(folio , transId)=>{
        
        setTransId(transId)
        setFolioNumber(folio)
        setShowmodal(true)
    }

    function refreshpage(){
        window.location.reload()
    }
    function getsipData(frequency){
        setShowSkeleton(true)
        setSelectedActive(frequency)
        const dataString={
            frequency:frequency
        }
        apiServices.mySipDatapostrequest(dataString).then(res => {
            if (res.data.status == "success") {
                setSipData(res?.data?.data)
                setShowSkeleton(false)
            }
        })
    }
    return (<>
        <BrowserView>
            <Header />
            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>My SIPs</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>My SIPs</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
            </div>
            <section className="sec-pad-sm">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                                        <div className="portfoliotab">
                                            <span>Filter By</span>
                                            {/* <span onClick={totalPortfolio} className={portfolioshow == 0 ? "portfoliotabfilter active" : "portfoliotabfilter"}>Total Portfolio</span> */}
                                            <span onClick={()=>{getsipData('MONTHLY')}} className={selectedActive == 'MONTHLY' ? "portfoliotabfilter active" : "portfoliotabfilter"}>Monthly SIP's</span>
                                            <span onClick={()=>{getsipData('DAILY')}} className={selectedActive == 'DAILY' ? "portfoliotabfilter active" : "portfoliotabfilter"}>Daily SIP's</span>
                                        </div>
                                    </div>
                        {showSkeleton == false ?
                            sipData.length > 0 ? <>
                                <div className="col-lg-12 border-end">

                                    {sipData.map((value) => (
                                        <div className="morderbox">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <div className="morderbox-fund">
                                                        <div className="tx-14 fw600">{value.us_scheme_name}</div>
                                                        <ul>
                                                            <li>Next SIP on {moment(value.us_start_date).format('DD')} of every month</li>
                                                            <li><b>({value.us_bank_name})</b></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="fundname-price line18">
                                                        {/* <div className="tx-16 fw600">{value.us_bank}</div>
                                                {value?.us_amount  ?<div className="tx-12 fw600">₹{value.us_amount}</div>:""} */}

                                                        <div className="line18">
                                                            <div className="tx-12">
                                                                <span className="fw600"> From:</span> {value.us_start_date}
                                                            </div>
                                                            <div className="tx-12">
                                                                <span className="fw600"> To: </span>{value.us_end_date}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1"><div className="fundstatus">
                                                    {value?.us_installment_amt ? <div className="tx-16 fw600">₹{value.us_installment_amt}</div> : ""}
                                                </div></div>
                                                <div className="col-lg-2">
                                                    <div className="fundname-date dflex align-items-center justify-content-end">
                                                        <div className="line18">
                                                            <span className={`badge ${value?.us_status == 'ACTIVE' ? "bg-success-subtle text-success" : value?.us_status == 'CXL' ? "bg-danger-subtle text-danger" : ""} `}>{value?.us_status}</span>
                                                        </div>

                                                    </div>

                                                </div>
                                                {value?.us_status == 'ACTIVE' ?
                                                    <div className="col-lg-1">
                                                        <div className="invboxsec">
                                                            <Dropdown className="actiondrop">
                                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="actiondrop-drop">
                                                                    <i class="icon-more2"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => { showreasonmodal(value.us_folio_no , value.us_xsip_reg_no) }}>Cancel <span className="ms-3"></span></Dropdown.Item>
                                                                    {/* <Dropdown.Item >Pause <span className="ms-3"></span></Dropdown.Item> */}

                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    
                                                :
                                                    <div className="col-lg-1">
                                                        <ReactTooltip />
                                                        <p data-tip={value.us_remark}><i className="icon-info22 ms-4"></i></p>
                                                        </div>
                                                 
                                                }

                                            </div>

                                        </div>))}
                                </div>

                            </> :
                                <div className="col-12">
                                    <div className="noimg">
                                        <img src="/img/noitemavailable.webp" alt="img" />
                                        <h6>No SIP's Found</h6>
                                        <a href="/mutual-funds" className="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                    </div>
                                </div> : ""}
                        {showSkeleton == true ?
                            <>
                                <div className="col-lg-12 border-end">

                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="100px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : ""}
                    </div>
                </div>
            </section>
            <Footer />
        </BrowserView>
        <MobileView>
            <header className="mheader">
                <div className="container">
                    <div className="mheader-left">
                        <div className="dflex align-items-center" >
                            <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/my-account") }}></i>
                            <div className="ms-3">
                                <h6 className="mb-0">My SIPs</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mobile-main">
            <div className="siptabs">
                        <ul>
                    
                            <li className={selectedActive == 'MONTHLY' ? "border-end active" : "border-end"} 
                            onClick={()=>{getsipData('MONTHLY')}}
                            >Monthly SIP's</li>
                            <li className={selectedActive == 'DAILY' ? "border-end active" : "border-end"} 
                            onClick={()=>{getsipData('DAILY')}}
                            >Daily SIP's</li>
                        </ul>
                    </div>
                <div className="p15">
                    {
                        
                            showSkeleton == false ?
                                sipData?.length > 0 ? <>
                                    {sipData.map((value) => (
                                        <div className="mysipbox">
                                            <div className="mysipbox-details">
                                                <h6 className="mb-0 tx-14">{value.us_scheme_name} <b>({value.us_bank_name})</b></h6>
                                               
                                            </div>
                                            <div className="mysipbox-footer border-top">
                                                <div>Next SIP on {moment(value.us_start_date).format('DD')} of every month.</div>
                                                <div>₹{value.us_installment_amt}</div>
                                            </div>
                                            <div className="mysipbox-footer border-top">
                                                <div>{value.us_start_date} to {value.us_end_date}</div>
                                                <span className={`badge ${value?.us_status == 'ACTIVE' ? "bg-success-subtle text-success" : value?.us_status == 'CXL' ? "bg-danger-subtle text-danger" : ""} `}>{value?.us_status}</span>
                                               {value?.us_status == 'ACTIVE' ?
                                             
                                                        <div className="invboxsec">
                                                            <Dropdown className="actiondrop">
                                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="actiondrop-drop">
                                                                    <i class="icon-more2"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => { showreasonmodal(value.us_folio_no , value.us_xsip_reg_no) }}>Cancel <span className="ms-3"></span></Dropdown.Item>
                                                                    {/* <Dropdown.Item >Pause <span className="ms-3"></span></Dropdown.Item> */}

                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                  
                                                    :
                                                
                                                  <>
                                                        <ReactTooltip />
                                                        <p data-tip={value.us_remark}><i className="icon-info22 ms-4"></i></p>
                                                        </>
                                                 
                                                }

                                              
                                            </div>
                                        </div>))}
                                </> : <>
                                    <div className="noimg">
                                        <img src="/img/notransactions.png" alt="img"/>
                                        <h6>No SIP's Found</h6>
                                        <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                    </div>
                                </> : "" }
                  
                </div>
            </div>
            <BottomMenu></BottomMenu>
        </MobileView>

        <Modal className="verifybse" show={showmodal} onHide={handlemodalclose}>
<Modal.Header>
<h4 className="text-center">SIP Cancellation</h4>
<button className="pop-close" onClick={() => { handlemodalclose() }}><i class="d-icon-times"></i></button>
</Modal.Header>
            <Modal.Body>
         {showOtpScreen == true ?
            <div className="verifybse-content" style={{marginTop:"-44px"}}>
                  
                    <p>OTP has been sent your register mobile number</p>
                    <div className="from-group mt-2">
                        <label>Verify OTP</label>
                        <input
                            type="number"
                            name="mobile_otp"
                            onChange={handleInput}
                        ></input>
                       <p className="mt-2 mb-0" style={{ fontSize: "12px" }}>
  We will send you OTP on 
  {portfolioMobileData && portfolioEmailData
    ? ` ${portfolioMobileData} & ${portfolioEmailData}.`
    : portfolioMobileData
    ? ` ${portfolioMobileData}.`
    : portfolioEmailData
    ? ` ${portfolioEmailData}.`
    : null}
</p>

                        {counter === 0 ? (
                            <p className="tx-12 ">
                                Didn't receive OTP? Resend{" "}
                                <span
                                    className="tx-theme"
                                    style={{ cursor: "pointer" }}
                                    onClick={resendOtpbr}
                                >
                                    (Click Here)
                                </span>
                            </p>
                        ) : (
                            <p className="tx-12 ">
                                Resend OTP in {counter} seconds
                            </p>
                        )}
                       
                        <button type="button" className="btn btn-primary btn-lg btn-full" onClick={verifybseonboardotp} disabled={isLoading}>  {isLoading ? (
                                                        <img src="/img/loder01.gif" width="60px" height="11px" alt="img" />
                                                    ) : (
                                                        "Verify"
                                                    )}</button>
                        {/* <button className="btn btn-primary btn-lg btn-full mt-1" onClick={uploadfile}>uploadfile</button> */}
                    </div>
                </div>   
           : <div className="verifybse-content" style={{marginTop:"-44px"}}>
                   
                    <div className="from-group mt-2">
                        <label>Reason for cancellation SIP</label>
                        <select
                        className="custom-select"
                            name="reason"
                            onChange={handleInput}
                        >
                            <option value="">Select Reason</option>
                            <option value="01">Non availability of Funds</option>
                            <option value="02">Scheme not performing</option>
                            <option value="03">Service issue</option>
                            <option value="04">Load Revised</option>
                            <optipn value="05">Wish to invest in other schemes</optipn>
                            <option value="06">Change in Fund Manager</option>
                            <option value="07">Goal Achieved</option>
                            <option value="08">Not comfortable with market volatility</option>
                            <option value="09">Will be restarting SIP after few months</option>
                            <option value="10">Modifications in bank/mandate/date etc</option>
                            <option value="11">I have decided to invest elsewhere</option>
                            <option value="12">This is not the right time to invest</option>
                        </select>
                       
                    </div>
                    <button type="button" className="btn btn-primary btn-lg btn-full mt-4" onClick={cancelSIP} disabled={isLoading}>  {isLoading ? (
                                                        <img src="/img/loder01.gif" width="60px" height="11px" img="alt" />
                                                    ) : (
                                                        "Send OTP"
                                                    )}</button>
                                                   
                      
                </div>}
               

            </Modal.Body>

        </Modal>
        <SweetAlert danger confirmBtnCssClass='alertpop' confirmBtnText='Ok' title={apiMessage} show={warningShow} onConfirm={refreshpage}>
            </SweetAlert>
            <SweetAlert success confirmBtnCssClass='alertpop' confirmBtnText='OK' title={apiMessage} show={show} onConfirm={refreshpage}>
            </SweetAlert>
    </>)
}
export default Mysips