import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";
import CustomProgressBar from '../../Components/Elements/progress_bar';
let apiServices = new ApiService();
function Bseonboardingstep3cont() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [taxStatus , setTaxStatus] = useState("")
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      const dataString = {
      }
      apiServices.bsegetuserdataPostRequest(dataString).then(result => {
        if (result.data.status == 'success') {
          setSpinnerLoading(false)
          setTaxStatus(result.data.bse_register_data.bur_tax_status)
          if (result.data.data.gs_communication_mode != null && result.data.data.gs_email_declaration_flag != null && result.data.data.gs_mobile_declaration_flag != null) {
            setFormData({
              ...formData,
              email: result.data.data.gs_email,
              mobile: result.data.data.gs_mobile,
              communicationMode: result.data.data.gs_communication_mode,
              emailDeclarationFlag: result.data.data.gs_email_declaration_flag,
              mobileDeclarationFlag: result.data.data.gs_mobile_declaration_flag,
            });
          } else {
            setSpinnerLoading(false)
            setFormData({
              ...formData,
              email: result.data.data.gs_email,
              mobile: result.data.data.gs_mobile,
            });
          }
        } else if (result.data.status == 'error' && result.data.message == 'Session expired') {
          navigate("/");
          setSpinnerLoading(false)
          localStorage.removeItem('AUTH_TOKEN');
        }
      })
    }
    didMountRef.current = false;
  }, []);


  const handleNextClick = () => {
    setIsLoading(true)
    const dataString = {
      communicationMode: formData.communicationMode,
      emaildeclarationflag: formData.emailDeclarationFlag,
      mobiledeclarationflag: formData.mobileDeclarationFlag,

    };
    apiServices.bseuseraddresscontPostRequest(dataString).then((result) => {
      if (result.data.status === 'success') {
        setIsLoading(false)
        // if (minorData === "02") {
        //   navigate('/bseonboarding/guardian-nominee');
        // } else {
          navigate('/bseonboarding/step4');
        // }
      } else if (result.data.status === "error" && result.data.message === "Session expired") {
        navigate("/");
        localStorage.removeItem('AUTH_TOKEN');
        setIsLoading(false)
      }

    })
      .catch(error => {
        console.error("API Request Error:", error);
      })
  };



  const handleBackClick = () => {
    if(taxStatus == 24 || taxStatus == 21){
      navigate("/bseonboarding/step3forg");
    }
    else{
    navigate("/bseonboarding/step3");
    }
  };



  const [formData, setFormData] = useState({
    mobile: '',
    email: '',
    communicationMode: 'E',
    emailDeclarationFlag: 'SE',
    mobileDeclarationFlag: 'SE',
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  return (
    <>
 <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>

      <BrowserView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />

        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center mt-3">
                <div className="col-lg-6">
                  <div className="crds">
                  <CustomProgressBar percent={42} />
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 4 of 7</p>
                        <h5>Communication Address(India)</h5>
                      </div>
                      <div className="row">
                      <div className="col-lg-12">
                        <div className="from-group mb-20">
                          <label>Email Belongs To<span className="tx-red">*</span></label>
                          <select
                            name="emailDeclarationFlag"
                            value={formData.emailDeclarationFlag}
                            className="custom-select"
                            onChange={handleInputChange}
                          >
                            <option value="SE">Self</option>
                            <option value="SP">Spouse</option>
                            <option value="DC"> Dependent Children </option>
                            <option value="DS"> Dependent Siblings</option>
                            <option value="DP">Dependent Parents</option>
                            <option value="GD"> Guardian</option>
                            <option value="PM">PMS</option>
                            <option value="CD">Custodian</option>
                            <option value="PO">POA</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="from-group mb-20">
                          <label>Mobile Belongs To<span className="tx-red">*</span></label>
                          <select
                            name="mobileDeclarationFlag"
                            value={formData.mobileDeclarationFlag}
                            className="custom-select"
                            onChange={handleInputChange}
                          >
                            <option value="SE">Self</option>
                            <option value="SP">Spouse</option>
                            <option value="DC"> Dependent Children </option>
                            <option value="DS"> Dependent Siblings</option>
                            <option value="DP">Dependent Parents</option>
                            <option value="GD"> Guardian</option>
                            <option value="PM">PMS</option>
                            <option value="CD">Custodian</option>
                            <option value="PO">POA</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {formData.email && formData?.mobile ? <>
                          <p className="mb-1 tx-12 ">Email id : {formData.email}  </p>
                          <p className="mb-3 tx-12 ">Mobile Number : {formData?.mobile}  </p>
                        </>
                          : ""}
                      </div>
</div>
                      <button className="btn btn-primary btn-lg btn-full mt-1" onClick={handleNextClick} disabled={isLoading}>
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Next"
                        )}
                      </button>
                      <button className="btn btn-outline-primary btn-lg btn-full mt-3" onClick={handleBackClick} >Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" onClick={handleBackClick}>
                <i class="icon-arrow-left8"></i>
                <div className="ms-3">
                  <h6 className="mb-0">Communication Address (India)</h6>
                  <p className="tx-12 mb-0 line18">Step 4 of 7</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
          <div className="mobile-form">
            <CustomProgressBar percent={42} />
            <div className="p15 pb-5 mb-5">
              <div className="row g-2">
                <div className="col-lg-12">
                  <div className="from-group mb-2">
                    <label>Email Belongs To<span className="tx-red">*</span></label>
                    <select
                      name="emailDeclarationFlag"
                      value={formData.emailDeclarationFlag}
                      onChange={handleInputChange}
                      className="custom-select"
                    >
                      <option value="SE">Self</option>
                      <option value="SP">Spouse</option>
                      <option value="DC"> Dependent Children </option>
                      <option value="DS"> Dependent Siblings</option>
                      <option value="DP">Dependent Parents</option>
                      <option value="GD"> Guardian</option>
                      <option value="PM">PMS</option>
                      <option value="CD">Custodian</option>
                      <option value="PO">POA</option>

                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="from-group mb-2">
                    <label>Mobile Belongs To<span className="tx-red">*</span></label>
                    <select
                      name="mobileDeclarationFlag"
                      value={formData.mobileDeclarationFlag}
                      onChange={handleInputChange}
                      className="custom-select"
                    >
                      <option value="SE">Self</option>
                      <option value="SP">Spouse</option>
                      <option value="DC"> Dependent Children </option>
                      <option value="DS"> Dependent Siblings</option>
                      <option value="DP">Dependent Parents</option>
                      <option value="GD"> Guardian</option>
                      <option value="PM">PMS</option>
                      <option value="CD">Custodian</option>
                      <option value="PO">POA</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                    {formData.email && formData?.mobile ? <>
                      <p className="mb-0 tx-12 ">Email id : {formData.email}  </p>
                      <p className="mb-0 tx-12 ">Mobile number : {formData?.mobile}  </p>
                    </>
                      : ""}
                  </div>
              </div>
            </div>
          </div>

        </div>
        <div className="footer-button">
          <div className='d-flex'>
            {/* <button className="btn btn-primary btn-lg btn-full me-2 " onClick={handleBackClick}>
                  Back
                </button> */}
            <button className="btn btn-primary btn-lg btn-full " onClick={handleNextClick} disabled={isLoading}>
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>

      </MobileView>

    </>

  )
}

export default Bseonboardingstep3cont
